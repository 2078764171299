const { datadogRum } = require("@datadog/browser-rum");

const APPLICATION_ID = "dc71d1b6-3891-4ba9-bf53-78e06463ef0a";
const CLIENT_TOKEN = "pub45347c95079698d9d8af2edbd0df0d8c";
const SITE = "datadoghq.eu";
const SERVICE = "homepage";
const SAMPLE_RATE = 1; // 1%

const init = () => {
  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    site: SITE,
    service: SERVICE,
    env: process.env.NODE_ENV,
    sampleRate: SAMPLE_RATE,
    premiumSampleRate: 0,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackSessionAcrossSubdomains: true
  });
};

module.exports = {
  init
};
