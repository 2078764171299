import { CLIENT_PERFORMANCE_MEASUREMENT } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";

const collect = metric => {
    const { label, name, value, ...otherProps } = metric;
    trackEvent(CLIENT_PERFORMANCE_MEASUREMENT, { kind: name, info1: label, value, data: otherProps });
}

module.exports = {
    collect
};