import "/styles/global.scss";
import "styles/normalize.scss";
import "styles/cookiehub.scss";
import "styles/graphiql.scss"
import "static/fonts/font.scss";

//import "@mondaydotcomorg/monday-ui-components/dist/main.css"; //For Dev env

import { collect } from 'services/analytics/handlers/bigbrain-handler';
import { NEXTJS_WEB_VITAL_LABEL } from 'constants/analytics';
import DatadogService from "services/datadog-service";

// Datadog
DatadogService.init();

export function reportWebVitals(metric) {
  if (metric.label && metric.label === NEXTJS_WEB_VITAL_LABEL) {
    collect(metric);
  }
}

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}
